import Button from '@core-ui/button';
import Loader from '@core-ui/loader';
import { COLOURS } from '@core-ui/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { formatTime } from '@/app/utils/dates';
import { getUserNotificationDict } from '@/dictionary/actions';
import { useAppSelector } from '@/hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import selectors from './selectors';
import { INotifications } from './types';

interface IRow {
  notification: INotifications;
}

const Row: React.FC<IRow> = (props) => {
  const { notification } = props;

  return (
    <Grid item container direction="column" padding="8px 0px" borderBottom={`1px solid ${COLOURS.Coconut.Solid[300]}`}>
      <Grid item container wrap="nowrap" columnGap={1}>
        <Avatar variant="rounded" sx={{ width: 20, height: 20 }} />
        <Grid item xs zeroMinWidth>
          <Typography
            variant="caption"
            color={COLOURS.Coconut.Solid[500]}
            noWrap
          >{`${notification.first_name} ${notification.last_name}`}</Typography>
        </Grid>
        <Typography>{formatTime(notification.created_at * 1000, { dateFormat: 'd MMM' })}</Typography>
      </Grid>
      <Grid item component={Typography} xs={12} color={COLOURS.Coconut.Solid[500]} marginTop="4px">
        {notification.title}
      </Grid>
    </Grid>
  );
};

const NotificationMenu = () => {
  const [showMore, setShowMore] = useState(false);
  const { notifications } = useAppSelector(selectors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserNotificationDict());
  }, []);

  const toggleShow = () => setShowMore(!showMore);

  return (
    <Grid container direction="column" padding="0px 12px" sx={{ minWidth: '260px' }}>
      {!notifications.length && <Loader mode="circle" padding={5} />}
      {notifications.slice(0, 5).map((notification, idx) => (
        <Row key={idx} notification={notification} />
      ))}
      {showMore && notifications.slice(5).map((notification, idx) => <Row key={idx} notification={notification} />)}

      <Button
        fullWidth
        variant="filled"
        color="black"
        label="More notification"
        sx={{ marginTop: '16px' }}
        onClick={toggleShow}
      />
    </Grid>
  );
};

export default NotificationMenu;
