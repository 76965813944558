import { Nullable } from '@core-ui/types';
import { IShowNotification } from '@/components/Notification/actions';
import { BoatConfig, BoatWithConfigSchemaModel, EmployeeSchema } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'app';

export const initApp = createAction(`${reducerName}.INIT_APP`);
export const getAppDictionaries = createAction('GET_APP_DICTIONARIES');
export const setLoadedActions = createAction(`${reducerName}.SET_LOADED_ACTIONS`);
export const setAppReady = createAction(`${reducerName}.SET_APP_READY`);
export const setWindowBreakpoint = createAction<number>(`${reducerName}.SET_WINDOW_BREAKPOINT`);
export const setCurrentVehicle = createAction<BoatWithConfigSchemaModel | undefined>(
  `${reducerName}.SET_CURRENT_VEHICLE`
);
export const setCurrentVehicleConfig = createAction<BoatConfig | undefined>(
  `${reducerName}.SET_CURRENT_VEHICLE_CONFIG`
);
export const historyPush = createAction(`${reducerName}.HISTORY_PUSH`);
export const getUser = createAction(`${reducerName}.GET_USER`);
export const setUser = createAction<Nullable<EmployeeSchema>>(`${reducerName}.SET_USER`);
export const setChatSocket = createAction<boolean>(`${reducerName}.SET_CHAT_SOCKET`);

export type Error = {
  status: number;
  message?: string;
  data?:
    | {
        errors?: Record<string, unknown>;
        message?: string;
        detail?: Array<{ msg: string }>;
      }
    | string;
};

export interface IErrorOptions {
  errorsMapper?: (errors: Record<string, unknown>) => Record<string, unknown>;
  defaultNotificationOptions?: IShowNotification;
  notificationOptions401?: IShowNotification;
  notificationOptions403?: IShowNotification;
  notificationOptions404?: IShowNotification;
  notificationOptions400?: IShowNotification;
}

export const setResponseError = createAction(`${reducerName}.SET_RESPONSE_ERROR`);

export const setUserCostArticlesMappingLoading = createAction<boolean>(
  `${reducerName}.SET_USER_COST_ARTICLES_MAPPING_LOADING`
);
export const toggleUserCostArticlesMapping = createAction(`${reducerName}.TOGGLE_USER_COST_ARTICLES_MAPPING`);
