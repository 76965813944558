import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { POST, setOceanAuthorization } from '@/api/oceanApi';
import { removeTokens } from '@/api/token-store';
import { historyPush, setCurrentVehicle } from '@/app/actions';
import { ROUTES } from '@/app/consts/routes';
import { responseError } from '@/app/sagas';
import { ISagaContext } from '@/app/types/common';
import { getClientCostArticleDict, getCostArticleDict, getRequestDict, getVendorDict } from '@/dictionary/actions';
import { BoatWithConfigSchemaModel } from '@/generated';
import history from '@/history';
import { all, put, select, takeLatest } from '@redux-saga/core/effects';
import { Action } from 'redux-actions';
import { call, getContext } from 'redux-saga/effects';
import * as actions from './actions';
import { allowedPaths } from './consts';
import { getBoatByIdSelector } from './selector';

function* updateSelectedBoat({ payload }: Action<actions.IUpdateSelectedBoat>) {
  try {
    const { boatId } = payload;

    const history: ISagaContext['history'] = yield getContext('history');
    const boat: BoatWithConfigSchemaModel = yield select(getBoatByIdSelector, { boatId });

    const isFAQ: boolean = history.location.pathname.startsWith(`/${ROUTES.FAQ}`);

    yield put(setCurrentVehicle(boat));

    if (isFAQ) {
      yield put(historyPush(buildURL(`/${ROUTES.FAQ}`)));
    } else {
      // \/\d+\/ matches "/{boat_id}/"
      const pathWithoutBoatId = history.location.pathname.replace(/\/\d+\//, '');

      let newPath: Nullable<string> = null;

      for (let i = 0; i < allowedPaths.length; i++) {
        if (pathWithoutBoatId.startsWith(allowedPaths[i])) {
          newPath = allowedPaths[i];
          break;
        }
      }

      // redirect to the "homepage" if the path was not found
      if (!newPath) {
        newPath = `${ROUTES.BACKOFFICE}`;
      }

      yield put(historyPush(buildURL(`/${boatId}/${newPath}`)));
    }

    // refetching dictionaries dependent on the boat
    yield all([
      put(getRequestDict()),
      put(getVendorDict()),
      put(getClientCostArticleDict()),
      put(getCostArticleDict()),
    ]);
  } catch (e) {
    yield call(responseError, e);
  }
}

function* logout() {
  try {
    yield call(POST, '/logout');

    // TODO: в данный момент эти две функции ничего не делают, т.к. авторизация происходит через httpOnly куки
    //  когда будет реализована авторизация через токены, это будет использоваться
    //  и initApp() ещё переделаем
    removeTokens();
    setOceanAuthorization(null);

    history.push(`/${ROUTES.LOGIN}`);
  } catch (e) {
    yield call(responseError, e);
  }
}

const sagas = [takeLatest(actions.updateSelectedBoat, updateSelectedBoat), takeLatest(actions.logout, logout)];

export default function* topMenuHeaderSagas() {
  yield all(sagas);
}
