import Autocomplete, { AutocompleteProps } from '@core-ui/autocomplete';
import Checkbox, { ICheckboxProps } from '@core-ui/checkbox';
import FieldHOC from '@core-ui/form_hoc';
import TextField, { TextFieldProps } from '@core-ui/text_field';
import DatePicker from '@core-ui/datepicker';
import TextFieldMasked from '@/components/TextFieldMasked';
import { ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';

export const TextFieldForm = FieldHOC(TextField) as ComponentType<
  FieldRenderProps<any, HTMLElement, any> & TextFieldProps
>;
export const TextFieldMaskedForm = FieldHOC(TextFieldMasked) as ComponentType<
  FieldRenderProps<any, HTMLElement, any> & TextFieldProps
>;
export const AutocompleteForm = FieldHOC(Autocomplete) as ComponentType<
  FieldRenderProps<any, HTMLElement, any> & AutocompleteProps
>;
export const DatePickerForm = FieldHOC(DatePicker) as ComponentType<FieldRenderProps<any, HTMLElement, any> & any>;
export const CheckboxForm = FieldHOC(Checkbox) as ComponentType<
  FieldRenderProps<any, HTMLElement, any> & ICheckboxProps
>;
