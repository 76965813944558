import { COLOURS } from '@core-ui/styles';
import { ApproveStatusEnum } from '@/generated';
import { IntlMessageKeys } from 'components/Intl';

export const colorsByStatus: Record<ApproveStatusEnum, Record<'text' | 'indicator', string>> = {
  [ApproveStatusEnum.AWAITING]: {
    text: COLOURS.Coconut.Solid[500],
    indicator: COLOURS.Coconut.Solid[600],
  },
  [ApproveStatusEnum.APPROVED]: {
    text: COLOURS.Avocado.Solid[300],
    indicator: COLOURS.Avocado.Solid[600],
  },
  [ApproveStatusEnum.DECLINED]: {
    text: COLOURS.Strawberry.Solid[300],
    indicator: COLOURS.Strawberry.Solid[600],
  },
};

export const textByStatus: Record<ApproveStatusEnum, IntlMessageKeys> = {
  [ApproveStatusEnum.AWAITING]: 'label.awaiting',
  [ApproveStatusEnum.APPROVED]: 'label.approved',
  [ApproveStatusEnum.DECLINED]: 'label.declined',
};
