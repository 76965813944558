import { Nullable } from '@core-ui/types';
import React from 'react';
import DropZone, { DropzoneState } from 'react-dropzone';

// TODO: навести порядок в этом компоненте, актуализировать типы, удалить везде reducerName и т.д.

export interface IFile {
  id: number;
  mime: string;
  size: number;
  title: string;
  extension: string;
  description: Nullable<string>;
  link: string;
  created_at: number;
  updated_at: number;
  is_attached: boolean;
}

const LoadFileDropzone: React.FC<IProps> = (props) => {
  const { accept, maxSize, disabled, noDrag, render, handleOnDrop } = props;

  const onDrop = (acceptedFiles: File[]) => {
    if (handleOnDrop) {
      handleOnDrop(acceptedFiles);
    }
  };

  return (
    <DropZone multiple accept={accept} onDrop={onDrop} maxSize={maxSize} disabled={disabled} noDrag={noDrag}>
      {(dropZoneProps: DropzoneState) => {
        return render(props, dropZoneProps);
      }}
    </DropZone>
  );
};

export interface IUniqueProps {
  onChange?: (value: IFile[]) => void;
}

export interface ICommonProps {
  reducerName: string; // уникальное имя поле в редьюсере, аналогично с redux-form
  disabled?: boolean;
}

export interface IPassedProps extends ICommonProps {
  accept?: string;
  maxSize?: number;
  render: (props: IProps, dropzoneProps: DropzoneState) => JSX.Element; // кастомный компонент
  renderPlaceholder?: () => JSX.Element;
  noDrag?: boolean;
  handleOnDrop?: (files: File[]) => void;
}

interface IProps extends IPassedProps, IUniqueProps {}

export default LoadFileDropzone;
