import { createApi } from '@core-ui/api';
import { Nullable } from '@core-ui/types';
import * as Sentry from '@sentry/browser';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { API_TIMEOUT } from '@/app/consts/app';
import { MATTERMOST_URL } from '@/app/consts/env';

const ChatApi = createApi();

export const { POST, GET, DELETE, PUT, PATCH } = ChatApi;

export const initChatAPI = () => {
  ChatApi.client.defaults.baseURL = `${MATTERMOST_URL}/`;
  ChatApi.client.defaults.timeout = API_TIMEOUT;
  ChatApi.client.defaults.withCredentials = true;
  ChatApi.client.defaults.paramsSerializer = {
    serialize: (params) => queryString.stringify(params),
  };

  ChatApi.client.defaults.headers.common = {
    'Content-Type': 'application/json',
  };

  ChatApi.client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (e) => {
      Sentry.withScope((scope) => {
        scope.setExtra('source-api', 'chats');
        Sentry.captureException(e);
      });

      return Promise.reject(e);
    }
  );
};

// TODO: нам нужно менять аутентификацию по подобию ская, и тогда эту штуку скорее всего переделаем
export const setChatAuthorization = (token: Nullable<string>) => {
  if (token) {
    ChatApi.client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};
