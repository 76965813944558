import { YachtSmall } from '@core-ui/icons';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { FormattedMessage, IntlMessageKeys } from 'components/Intl';

interface IProps {
  titleId?: IntlMessageKeys;
  subtitleId?: Nullable<IntlMessageKeys>;
  styles?: SxProps<Theme>;
  extraElement?: React.ReactNode | JSX.Element;
  dataTestId?: string;
}

const NoDataPlaceholder: FC<IProps> = (props) => {
  const {
    titleId = 'no_data.title',
    subtitleId = 'no_data.subtitle',
    styles,
    extraElement,
    dataTestId = 'table-no-data-placeholder',
  } = props;

  return (
    <Grid container direction="column" alignItems="center" marginTop={20} sx={styles}>
      <YachtSmall />

      <Grid item marginBottom={2} marginTop={8}>
        <Typography variant="h5">
          <FormattedMessage id={titleId} />
        </Typography>
      </Grid>

      {subtitleId && (
        <Grid item>
          <Typography color="text.secondary">
            <FormattedMessage id={subtitleId} />
          </Typography>
        </Grid>
      )}

      {extraElement && (
        <Grid item marginTop={6}>
          {extraElement}
        </Grid>
      )}
    </Grid>
  );
};

export default NoDataPlaceholder;
