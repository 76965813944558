import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  imageContainer: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    position: 'absolute',
    rowGap: 4,
    overflow: 'scroll',
  },
  buttonsContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    justifyContent: 'center',
    bottom: '20px',
  },
  buttons: {
    backgroundColor: `${COLOURS.Coconut.Solid[900]} !important`,
    borderRadius: 4,
  },
});
