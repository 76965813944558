import { LogoIconOcean } from '@core-ui/icons';
import { FullMenu, Logout, MySkyHeader, OptionsPanel } from '@core-ui/my_sky_header';
import { useParams } from '@core-ui/redux-router';
import { COLOURS } from '@core-ui/styles';
import { ITab } from '@core-ui/tabs';
import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MuiMenu from '@mui/material/Menu';
import { IS_PROD_ENVIRONMENT } from '@/app/consts/env';
import { ROUTES } from 'app/consts/routes';
import { useIntl } from 'components/Intl';
import NotificationMenu from '@/components/NotificationMenu';
import { logout } from 'components/TopMenuHeader/actions';
import history from '@/history';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { FINANCES_TABS } from '@/pages/finances/consts';
import { APPLICATION_PATHS } from '@/pages/routes';
import { SETTINGS_TABS } from '@/pages/settings/consts';
import { ApplicationPaths } from '@/pages/types';
import { Bell } from '@phosphor-icons/react';
import React, { CSSProperties, Dispatch, FC, MouseEvent, useState } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import BoatSelection from './components/BoatSelection';

const logoStyles: CSSProperties = {
  cursor: 'pointer',
};

const makeTabs = (boatId: Nullable<string>): ITab[] => [
  {
    value: ROUTES.BACKOFFICE,
    label: <FormattedMessage id="menu.backoffice" />,
    dataTestId: 'backoffice-tab',
    to: buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.INBOX}`),
  },
  {
    value: ROUTES.FINANCES,
    label: <FormattedMessage id="menu.finances" />,
    dataTestId: 'finances-tab',
    to: buildURL(`/${boatId}/${ROUTES.FINANCES}/${FINANCES_TABS.BUDGET}`),
  },
  {
    value: ROUTES.ANALYTICS,
    label: <FormattedMessage id="menu.analytics" />,
    dataTestId: 'analytics-tab',
    to: buildURL(`/${boatId}/${ROUTES.ANALYTICS}`),
  },
  {
    value: ROUTES.DOCUMENTS,
    label: <FormattedMessage id="menu.documents" />,
    dataTestId: 'documents-tab',
    to: buildURL(`/${boatId}/${ROUTES.DOCUMENTS}`),
  },
  {
    value: ROUTES.CHATS,
    label: <FormattedMessage id="menu.chats" />,
    dataTestId: 'chats-tab',
    to: buildURL(`/${boatId}/${ROUTES.CHATS}`),
  },
];

const makeUserActions = (dispatch: Dispatch<any>, boatId: Nullable<string>) => [
  // todo: uncomment after testing
  // {
  //   component: <FormattedMessage id="menu.faq" />,
  //   onClick: () => {
  //     history.push(`/${ROUTES.FAQ}`);
  //   },
  // },
  {
    component: <FormattedMessage id="menu.settings" />,
    onClick: () => {
      history.push(buildURL(`/${boatId}/${ROUTES.SETTINGS}/${SETTINGS_TABS.PROFILE}`));
    },
  },
  {
    component: <FormattedMessage id="menu.logout" />,
    onClick: () => {
      dispatch(logout());
    },
  },
];

const iconBtnStyle = {
  width: '32px',
  height: '32px',
  backgroundColor: COLOURS.Coconut.Solid[600],
};

const TopMenuHeader: FC = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const params = useParams<ApplicationPaths>(APPLICATION_PATHS);
  const dispatch = useDispatch();
  const intl = useIntl();

  const open = Boolean(anchorEl);
  const tabs = makeTabs(params.boatId);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoClick = () => {
    history.push(buildURL(`/${params.boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.INBOX}`));
  };

  if (location.pathname === `/${ROUTES.LOGIN}` || location.pathname === `/${ROUTES.MOBILE}`) {
    return null;
  }

  return (
    <MySkyHeader>
      <Grid item container alignItems="center" width="auto" onClick={handleLogoClick}>
        <LogoIconOcean
          width={100}
          style={logoStyles}
          fill={IS_PROD_ENVIRONMENT ? COLOURS.WHITE : COLOURS.Banana.Solid[600]}
        />
      </Grid>

      <BoatSelection />

      <FullMenu tabs={tabs} activeMenu={params.route || ROUTES.BACKOFFICE} />

      <OptionsPanel>
        <IconButton
          onClick={handleClick}
          style={iconBtnStyle}
          aria-label={intl.formatMessage({ id: 'aria.label.show_notifications' })}
        >
          <Bell />
        </IconButton>

        <Logout actions={makeUserActions(dispatch, params.boatId)} />
      </OptionsPanel>

      <MuiMenu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ marginTop: '14px' }}>
        <NotificationMenu />
      </MuiMenu>
    </MySkyHeader>
  );
};

export default TopMenuHeader;
