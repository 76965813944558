import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import React from 'react';
import useStyles from './styles';

interface IListTooltipProps {
  list?: Nullable<any[]>;
  getLabel?: (obj: any) => React.ReactNode;
  hideFirst?: boolean;
  firstInTooltip?: boolean;
  wrapperStyles?: SxProps;
  itemStyles?: SxProps;
}

const ListTooltip = (props: IListTooltipProps) => {
  const { list, getLabel, hideFirst, firstInTooltip, wrapperStyles, itemStyles } = props;

  const styles = useStyles();

  if (!list || list.length === 0) {
    return <span>&mdash;</span>;
  }

  if (list.length === 1 && !firstInTooltip) {
    return <>{getLabel ? getLabel(list[0]) : list[0]}</>;
  }

  const labelList: React.ReactNode[] = list.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Grid key={index} sx={itemStyles}>
      {getLabel ? getLabel(item) : <>{item}</>}
    </Grid>
  ));

  const isMore15Elements = list.length > 15;

  return (
    <Grid container gap={2} alignItems="center" wrap="nowrap" sx={wrapperStyles}>
      {!hideFirst && labelList[0]}

      <Tooltip
        placement={isMore15Elements ? 'right' : 'top'}
        title={
          <Typography sx={{ ...(isMore15Elements ? styles.bigLabels : styles.labels) }}>
            {hideFirst ? labelList : labelList.slice(1)}
          </Typography>
        }
        arrow
      >
        <Typography component="span" sx={styles.badge}>
          +{hideFirst ? list.length : list.length - 1}
        </Typography>
      </Tooltip>
    </Grid>
  );
};

export default ListTooltip;
