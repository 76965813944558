/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RequestSortingColumnsV2Enum {
  NAME = 'NAME',
  REQUEST_STATUS_ID = 'REQUEST_STATUS_ID',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  CREATED_AT = 'CREATED_AT',
  CREATED_BY_EMAIL = 'CREATED_BY_EMAIL',
}
