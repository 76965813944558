import Typography from '@mui/material/Typography';
import { FC } from 'react';
import cls from 'classnames';
import useStyles from './styles';
import { StatusInfoColors } from './types';

interface IProps {
  color: StatusInfoColors;
  onClick?: () => void;
}

const StatusInfo: FC<IProps> = (props) => {
  const { children, color, onClick, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography
      className={cls(classes.root, classes[color])}
      onClick={onClick}
      {...rest} // rest не убирать, тултип навешивает обработчики
    >
      {children}
    </Typography>
  );
};

export default StatusInfo;
