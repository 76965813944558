import { createApi } from '@core-ui/api';
import { Nullable } from '@core-ui/types';
import * as Sentry from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { API_TIMEOUT } from '@/app/consts/app';
import { BACKEND_URL } from '@/app/consts/env';

const OceanApi = createApi();

export const { POST, GET, DELETE, PUT, PATCH } = OceanApi;

export const initOceanAPI = () => {
  OceanApi.client.defaults.baseURL = `${BACKEND_URL}/`;
  OceanApi.client.defaults.timeout = API_TIMEOUT;
  OceanApi.client.defaults.withCredentials = true;
  OceanApi.client.defaults.paramsSerializer = {
    serialize: (params) => queryString.stringify(params),
  };

  OceanApi.client.defaults.headers.common = {
    'Content-Type': 'application/json',
  };

  OceanApi.client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (e) => {
      if (axios.isCancel(e)) {
        throw e;
      }

      Sentry.withScope((scope) => {
        scope.setExtra('source-api', 'spend');
        Sentry.captureException(e);
      });

      return Promise.reject(e);
    }
  );
};

// TODO: нам нужно менять аутентификацию по подобию ская, и тогда эту штуку скорее всего переделаем
export const setOceanAuthorization = (token: Nullable<string>) => {
  if (token) {
    OceanApi.client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};
