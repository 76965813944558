import { COLOURS } from '@core-ui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { StatusInfoColors } from './types';

const successStyle = {
  backgroundColor: COLOURS.Salad.Solid[200],
};
const infoStyle = {
  backgroundColor: COLOURS.Fig.Solid[200],
};
const dangerStyle = {
  backgroundColor: COLOURS.Strawberry.Solid[200],
};
const pendingStyle = {
  backgroundColor: COLOURS.Orange.Solid[200],
};
const primaryStyle = {
  backgroundColor: COLOURS.Grenade.Solid[200],
};
const draftStyle = {
  backgroundColor: COLOURS.Passionfruit.Solid[200],
};
const approvalStyle = {
  backgroundColor: COLOURS.Banana.Solid[200],
};
const approvedStyle = {
  backgroundColor: COLOURS.Blueberry.Solid[200],
};
const deliveredStyle = {
  backgroundColor: COLOURS.Fig.Solid[200],
};
const doneStyle = {
  backgroundColor: COLOURS.Avocado.Solid[200],
};
const digitizedStyle = {
  backgroundColor: COLOURS.Grape.Solid[200],
};

export default makeStyles({
  root: {
    whiteSpace: 'nowrap',
    padding: '0 4px',
    borderRadius: 4,
    textTransform: 'uppercase',
  },
  [StatusInfoColors.INFO]: infoStyle,
  [StatusInfoColors.SUCCESS]: successStyle,
  [StatusInfoColors.DANGER]: dangerStyle,
  [StatusInfoColors.PRIMARY]: primaryStyle,
  [StatusInfoColors.PENDING]: pendingStyle,
  [StatusInfoColors.DRAFT]: draftStyle,
  [StatusInfoColors.APPROVAL]: approvalStyle,
  [StatusInfoColors.APPROVED]: approvedStyle,
  [StatusInfoColors.DELIVERED]: deliveredStyle,
  [StatusInfoColors.DONE]: doneStyle,
  [StatusInfoColors.DIGITIZED]: digitizedStyle,
});
