import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { TRANSITION_TIMING_FUNCTION } from '@/app/consts/app';

export default (): Record<string, SxProps<Theme>> => ({
  defaultStyles: {
    backgroundColor: `${COLOURS.WHITE} !important`,
    borderRadius: '16px !important',
    borderColor: `${COLOURS.Coconut.Solid[200]} !important`,
    color: `${COLOURS.Coconut.Solid[800]} !important`,
    transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,

    '&:hover': {
      borderColor: `${COLOURS.Coconut.Solid[500]} !important`,
      color: `${COLOURS.Coconut.Solid[900]} !important`,
    },
  },
});
