import { Nullable } from '@core-ui/types';
import { getAmountFormatter } from '@/dictionary/selector';
import { useAppSelector } from '@/hooks/useAppSelector';
import isNil from 'lodash-es/isNil';
import { FC, useMemo } from 'react';

interface IProps {
  amount?: Nullable<number>;
  currencyId?: number;
}

// TODO: удалить этот компонент при удалении старой страницы реквеста
/*
 * Deprecated component, use getAmountFormatter selector instead.
 * This component will be deleted during the Request section rework
 */
const Amount: FC<IProps> = (props) => {
  const { amount, currencyId } = props;

  const memoizedSelector = useMemo(() => getAmountFormatter, []);

  const currencyIntl = useAppSelector((state) => memoizedSelector(state));

  if (isNil(amount) || !currencyIntl) {
    return null;
  }

  return (
    <>
      {currencyIntl(amount)
        .replace(/[A-Za-z]/g, '')
        .trim()}
    </>
  );
};

export default Amount;
