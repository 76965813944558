import { COLOURS } from '@core-ui/styles';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { IRouteParams } from '@/app/consts/routes';
import { useAppSelector } from '@/hooks/useAppSelector';
import { IOpenLayerFeature } from '@/pages/backoffice/Requests/types';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import selector from './selectors';
import { featureFabric, layers, mapExtent, pointStyle, view, zoom } from './utils';

const mapStyle: SxProps<Theme> = {
  flex: 1,
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: COLOURS.BACKGROUND.DISABLED,
};

interface IProps {
  features?: IOpenLayerFeature[] | null;
  onPinSelect?: (item: IOpenLayerFeature) => void;
  edit?: boolean;
  createRemoveFeature?: (item: number[], deleted?: boolean) => void;
  callChildClearPoints?: MutableRefObject<unknown>;
}

const OLMap: React.FC<IProps> = (props) => {
  const { features, onPinSelect, edit, createRemoveFeature, callChildClearPoints } = props;
  const { boatId } = useParams<IRouteParams>();

  const [map, setMap] = useState<Map | null>(null);
  const { mapId } = useAppSelector((state) => selector(state, { boatId }));

  const mapElement = useRef<HTMLDivElement | null>(null);

  // create state ref that can be accessed in OpenLayers onclick callback function
  //  https://stackoverflow.com/a/60643670
  const mapRef = useRef<Map>();

  const selectPinClick = useCallback(
    (e) => {
      mapRef.current!.forEachFeatureAtPixel(e.pixel, (f) => {
        onPinSelect && onPinSelect(f.getProperties() as IOpenLayerFeature);

        return true;
      });
    },
    [onPinSelect]
  );

  const { source, vector } = useMemo(() => {
    const source = new VectorSource({
      overlaps: false,
      useSpatialIndex: false,
    });
    const vector = new VectorLayer({
      source,
      style: pointStyle(),
    });

    return { source, vector };
  }, []);

  const onAddFeature = (geometry: any) => {
    const newFeature = new Feature({ geometry: new Point(geometry) });
    source.addFeature(newFeature);
    createRemoveFeature && createRemoveFeature(geometry);
  };
  const onRemoveFeature = (feature: Feature) => {
    source.removeFeature(feature);
    createRemoveFeature &&
      // @ts-ignore
      createRemoveFeature(feature.getGeometry().flatCoordinates, true);
  };
  const clearAllPoints = () => {
    source.clear();
  };

  useEffect(() => {
    const initialMap = new Map({
      controls: [zoom()],
      // @ts-ignore
      target: mapElement.current,
      layers: [layers(mapId), vector],
      view: view(),
    });

    setMap(initialMap);
    mapRef.current = initialMap;

    if (callChildClearPoints) {
      callChildClearPoints.current = clearAllPoints;
    }
  }, []);

  const createPinClick = useCallback(
    (event) => {
      const feature = map!.forEachFeatureAtPixel(
        event.pixel,
        function (feature) {
          return feature;
        },
        {
          hitTolerance: 2,
        }
      );

      return !feature ? onAddFeature(event.coordinate) : onRemoveFeature(feature as Feature);
    },
    [map]
  );

  useEffect(() => {
    map && map!.on('click', edit ? createPinClick : selectPinClick);

    return () => {
      map && map!.un('click', edit ? createPinClick : selectPinClick);
    };
  }, [map, selectPinClick, createPinClick, edit]);

  useEffect(() => {
    // @ts-ignore
    map && map!.getView().fit(mapExtent, map!.getSize());
  }, [map]);

  useEffect(() => {
    if (features?.length) {
      const f = [];
      // don't work with features.map ??? WTF
      // eslint-disable-next-line no-restricted-syntax
      for (const t of features) {
        f.push(featureFabric(t));
      }

      source.clear();
      source.addFeatures(f);
    } else {
      source.clear();
    }
  }, [features]);

  return <Box ref={mapElement} className="map-container" sx={mapStyle} />;
};

export default OLMap;
