import { BoatWithConfigSchemaModel, ClientCostArticleInfo, RequestShortInfoSchema, VendorsInfo } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'dictionary';

export const getBoatList = createAction(`${reducerName}.GET_BOAT_DICTIONARY`);
export const setBoatDict = createAction<BoatWithConfigSchemaModel[]>(`${reducerName}.SET_BOAT_DICTIONARY`);

export const getCurrencyDict = createAction(`${reducerName}.GET_CURRENCY_DICTIONARY`);
export const getDepartmentDict = createAction(`${reducerName}.GET_DEPARTMENT_DICTIONARY`);
export const getEmployeeDict = createAction(`${reducerName}.GET_EMPLOYEE_DICTIONARY`);
export const getCostCenterDict = createAction(`${reducerName}.GET_COST_CENTER_DICTIONARY`);
export const getCostArticleDict = createAction(`${reducerName}.GET_COST_ARTICLE_DICTIONARY`);

export const getClientCostArticleDict = createAction(`${reducerName}.GET_CLIENT_COST_ARTICLE_DICTIONARY`);
export const setClientCostArticleDict = createAction<ClientCostArticleInfo[]>(
  `${reducerName}.SET_CLIENT_COST_ARTICLE_DICTIONARY`
);

export const getVendorDict = createAction(`${reducerName}.GET_VENDOR_DICTIONARY`);
export const setVendorDict = createAction<VendorsInfo[]>(`${reducerName}.SET_VENDOR_DICTIONARY`);

export const getRequestDict = createAction(`${reducerName}.GET_REQUEST_DICTIONARY`);
export const setRequestDict = createAction<RequestShortInfoSchema[]>(`${reducerName}.SET_REQUEST_DICTIONARY`);

export const getUserNotificationDict = createAction(`${reducerName}.GET_USER_NOTIFICATION_DICTIONARY`);
export const setUserNotificationDict = createAction(`${reducerName}.SET_USER_NOTIFICATION_DICTIONARY`);
