import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  badge: {
    marginLeft: 'auto',
    alignSelf: 'flex-start',
    display: 'inline',
    whiteSpace: 'nowrap',
    fontSize: 10,
    textTransform: 'uppercase',
    padding: '2px 8px',
    fontWeight: 500,
    borderRadius: 2,
    backgroundColor: COLOURS.WHITE,
    cursor: 'pointer',
  },

  labels: {
    overflow: 'auto',

    '&>*': {
      marginBottom: 5,

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },

  bigLabels: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& > *': {
      marginRight: 5,
      marginBottom: 5,

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
});
