import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { TRANSITION_TIMING_FUNCTION } from '@/app/consts/app';

export default (): Record<string, SxProps<Theme>> => ({
  approved: {
    minWidth: 'auto',
    width: 24,
    height: 24,
    background: COLOURS.Avocado.Solid[100],
    border: `1px solid ${COLOURS.Avocado.Solid[600]}`,
    borderRadius: '50%',
  },
  declined: {
    minWidth: 'auto',
    width: 24,
    height: 24,
    background: COLOURS.Strawberry.Solid[100],
    border: `1px solid ${COLOURS.Strawberry.Solid[600]}`,
    borderRadius: '50%',
  },
  waiting: {
    minWidth: 'auto',
    width: 24,
    height: 24,
    background: COLOURS.Coconut.Solid[200],
    border: `1px solid ${COLOURS.Coconut.Solid[300]}`,
    borderRadius: '50%',
  },
  statusIndicator: {
    height: '100%',
    width: 4,
    borderRadius: 8,
  },
  userListContainer: {
    '&::-webkit-scrollbar': {
      width: 4,
      background: COLOURS.CLEAR,
    },
    '&::-webkit-scrollbar-track': {
      background: COLOURS.CLEAR,
    },
    '&::-webkit-scrollbar-thumb': {
      background: COLOURS.LABEL.MAIN,
      borderRadius: 8,
    },
  },
  userTooltipText: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
