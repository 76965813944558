import { COLOURS } from '@core-ui/styles';
import makeStyles from '@mui/styles/makeStyles';

// TODO: избавиться от makeStyles (без него сейчас не работает)
export default makeStyles({
  root: {
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: 30,
    padding: '18px',
    margin: 0,
    bottom: '20px',
    background: COLOURS.Coconut.Solid[900],
    border: 0,
    borderRadius: '16px',
    zIndex: 2,
    transition: 'all 300ms ease-in-out',

    '& svg': {
      color: COLOURS.WHITE,
      transition: 'all 300ms ease-in-out',
    },

    '&:hover': {
      borderColor: `${COLOURS.Coconut.Solid[500]}`,
    },
  },

  left: {
    left: '20px',
  },

  right: {
    right: '20px',
    transform: 'rotate(180deg)',
  },

  leftActive: {
    left: 'calc(100% + 20px)',

    '& svg': {
      transform: 'rotate(180deg)',
    },
  },

  rightActive: {
    right: 'calc(100% + 20px)',

    '& svg': {
      transform: 'rotate(-180deg)',
    },
  },
});
