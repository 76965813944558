import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import { generateEmptyArray } from '@/app/utils/app';
import React, { FC } from 'react';

const headerStyle: SxProps<Theme> = {
  minHeight: 38,
  backgroundColor: COLOURS.Coconut.Solid[700],
};

const rowStyle: SxProps<Theme> = {
  minHeight: 20,
  margin: '10px 0',
};

interface IProps {
  rows: number;
}

const TableSkeleton: FC<IProps> = (props) => {
  const { rows } = props;

  return (
    <Grid container direction="column" wrap="nowrap" overflow="hidden">
      <Skeleton variant="rounded" sx={headerStyle} height={38} />

      {generateEmptyArray(rows).map((_, index) => (
        <Skeleton key={index} sx={rowStyle} variant="rounded" height={20} />
      ))}
    </Grid>
  );
};

export default TableSkeleton;
