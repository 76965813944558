import ENLocale from '@/app/locales/en.json';
import { createAction } from 'redux-actions';

export type Variant = 'success' | 'info' | 'warning' | 'error';

export interface IShowNotification {
  variant: Variant;

  title?: string;
  titleId?: keyof typeof ENLocale;

  message?: string | string[];
  messageId?: keyof typeof ENLocale | keyof typeof ENLocale[];

  autoHideDuration?: number;
}

export interface ICloseNotification {
  variant: Variant;
}

export const showNotification = createAction<IShowNotification>('SHOW_NOTIFICATION');
export const closeNotification = createAction<ICloseNotification>('CLOSE_NOTIFICATION');
