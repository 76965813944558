import Button from '@core-ui/button';
import { DocumentSmall } from '@core-ui/icons';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FormattedMessage, IntlMessageKeys } from 'components/Intl';
import React from 'react';

interface IProps {
  clearFilters: () => void;
  titleId?: IntlMessageKeys;
  subtitleId?: IntlMessageKeys;
  styles?: SxProps<Theme>;
}

const NoFiltratedDataPlaceholder = (props: IProps) => {
  const { clearFilters, titleId = 'no_filtered_data.title', subtitleId = 'no_filtered_data.subtitle', styles } = props;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      marginTop={20}
      sx={styles}
    >
      <DocumentSmall />

      <Grid item marginBottom={2} marginTop={8}>
        <Typography variant="h5">
          <FormattedMessage id={titleId} />
        </Typography>
      </Grid>

      <Grid item>
        <Typography color="text.secondary">
          <FormattedMessage id={subtitleId} />
        </Typography>
      </Grid>

      <Grid item marginTop={6}>
        <Button
          variant="contained"
          color="blue"
          label={<FormattedMessage id="label.reset_filters" />}
          onClick={clearFilters}
        />
      </Grid>
    </Grid>
  );
};

export default NoFiltratedDataPlaceholder;
