/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}
