import EN from 'app/locales/en.json';
import type { ReactNode } from 'react';
import React from 'react';
import type { IntlFormatters } from 'react-intl';
import {
  createIntl as ReactCreateIntl,
  createIntlCache as ReactCreateIntlCache,
  FormattedMessage as ReactFormattedMessage,
  IntlProvider as ReactIntlProvider,
  useIntl as useReactIntl,
} from 'react-intl';
import type { Props } from 'react-intl/src/components/message';

type IntlMessageKeys = keyof typeof EN;
type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>;
type FormattedMessageProps = Props<Record<string, ReactNode>> & {
  id?: IntlMessageKeys;
};

const FormattedMessage = (props: FormattedMessageProps) => {
  const { id, ...rest } = props;

  return <ReactFormattedMessage id={id} {...rest} />;
};

const useIntl = () => {
  const { formatMessage, ...rest } = useReactIntl();

  const typedFormatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id?: IntlMessageKeys;
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2]
  ) => {
    return formatMessage(descriptor, values, options);
  };

  return {
    ...rest,
    formatMessage: typedFormatMessage,
  };
};

export {
  IntlMessageKeys,
  FormattedMessage,
  useIntl,
  ReactCreateIntl as createIntl,
  ReactCreateIntlCache as createIntlCache,
  ReactIntlProvider as IntlProvider,
};
