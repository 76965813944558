import { FileStatusEnum, UploadingSourceEnum } from '@/generated';
import { LEDGER_DOCUMENT_TYPE_ENUM } from '@/pages/backoffice/Ledger/consts';
import capitalize from 'lodash-es/capitalize';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

export const APP_STORE_APP_URL = 'https://apps.apple.com/app/myocean-crewapp/id1576354367';
export const PLAY_STORE_APP_URL = 'https://play.google.com/store/apps/details?id=com.mysky.oceanapp';
export const STATIC_URL = 'https://static.mysky.com';
export const FAQ_URL = 'https://static.mysky.com/guides/ocean/index.html';

export const API_TIMEOUT = 30000;
export const TRANSITION_TIMING_FUNCTION = 'cubic-bezier(0.4, 0, 0.2, 1)';
export const DATE_FORMAT = 'dd/MM/YYYY';
export const MONTH_YEAR_DATE_FORMAT = 'MM/YYYY';

export enum CLIENT_DEVICE_OS {
  IOS = 'ios',
  ANDROID = 'android',
  UNKNOWN = 'unknown',
}

export enum MIME_TYPE_ENUM {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  GIF = 'image/gif',
  WEBP = 'image/webp',
  EPUB = 'application/epub+zip',
  TXT = 'text/plain',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  XLS = 'application/xls',
  EXCEL = 'application/vnd.ms-excel',
  EXCEL_SHEET = 'application/vnd.ms-excel.sheet.macroEnabled.12',
  OPEN_XML_SHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  OPEN_XML_TEMPLATE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  DOC = 'application/doc',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  WORD_DOC = 'application/msword',
  CSV = 'text/csv',
  OCTET_STREAM = 'application/octet-stream',
}

export enum ICostTypeEnum {
  FIXED = 'fixed',
  VARIABLE = 'variable',
}

export const ICostType = Object.values(ICostTypeEnum);

export const UPLOADED_FROM_OPTIONS = Object.values(UploadingSourceEnum).map((option) => ({
  label: capitalize(option.replace(/_/g, ' ')),
  value: option,
}));

export const FILE_STATUS_OPTIONS = Object.values(FileStatusEnum).map((option) => ({
  label: capitalize(option.replace(/_/g, ' ')),
  value: option,
}));

export const DOCUMENT_TYPE_OPTIONS = Object.values(LEDGER_DOCUMENT_TYPE_ENUM).map((option) => ({
  label: capitalize(option.replace(/_/g, ' ')),
  value: option,
}));

export const COST_TYPE_OPTIONS = ICostType.map((item) => ({
  label: item.charAt(0).toUpperCase() + item.substring(1),
  value: item,
}));

export const RANGE_PICKER_LABELS: [React.ReactNode, React.ReactNode] = [
  <FormattedMessage id="label.from" key="label.from" />,
  <FormattedMessage id="label.to" key="label.to" />,
];
