import { BREAKPOINTS } from '@core-ui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { GRID_COLUMN_BASE, TOP_MENU_HEIGHT } from '@/app/styles/consts';

export default makeStyles(
  {
    root: {
      height: `calc(100vh - ${TOP_MENU_HEIGHT}px)`,
      maxWidth: BREAKPOINTS.XL,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: `calc(100%/${GRID_COLUMN_BASE})`,
      paddingRight: `calc(100%/${GRID_COLUMN_BASE})`,
      paddingTop: `calc(100%/${GRID_COLUMN_BASE})`,
      paddingBottom: `calc(100%/${GRID_COLUMN_BASE})`,
    },
  },
  { name: 'ScreenLayout' }
);
