/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum StatusV2Enum {
  BEING_DIGITIZED = 'being_digitized',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
  APPROVED = 'approved',
  PAID = 'paid',
  DECLINED = 'declined',
}
