import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FileIcon from '@/components/FileIcon';
import { memo } from 'react';

const ImageWrapper = (props: IProps) => {
  const { file } = props;

  return <FileIcon file={file} />;
};

const DocWrapper = (props: IProps) => {
  const { file } = props;

  return (
    <Grid container justifyContent="center" borderRadius="4px">
      <Grid item marginTop="5px">
        <FileIcon file={file} />
      </Grid>
      <Grid
        item
        zeroMinWidth
        padding="0px 4px"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography noWrap variant="caption">
          {file.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface IProps {
  file: File;
  // eslint-disable-next-line react/no-unused-prop-types
  removeFile: (f: File) => void;
}

const UploadedFileRow = (props: IProps) => {
  const { file, removeFile } = props;

  const renderChild = () => {
    switch (file.type) {
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return <ImageWrapper {...props} />;
      default:
        return <DocWrapper {...props} />;
    }
  };

  return (
    <Paper elevation={0} sx={{ backgroundColor: '#E2E5E8', width: '76px', height: '76px' }}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        {renderChild()}
      </div>
    </Paper>
  );
};

export default memo(UploadedFileRow);
